import { FeaturesReady } from '@growthbook/growthbook-react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React from 'react';

import AuthModal from '@/components/auth/AuthModal/AuthModal';
import CartSlidingPane from '@/components/cart/CartSlidingPane/CartSlidingPane';
import Logo from '@/components/common/MegaNav/Logo/Logo';
import MegaNavMobile from '@/components/common/MegaNav/MegaNavMobile/MegaNavMobile';
import NavLinks from '@/components/common/MegaNav/NavLinks/NavLinks';
import UserActions from '@/components/common/MegaNav/UserActions/UserActions';
import MenuBar from '@/components/common/Nav/MenuBar/MenuBar';
import { useAuthModal } from '@/hooks/useAuthModal';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

import { ImpersonationBanner } from '../ImpersonationBanner/ImpersonationBanner';

export type NavbarProps = {
  menuBar: boolean;
  setMenuBar: (menuBar: boolean) => void;
  setIsBlur: (isBlur: boolean) => void;
};

const MegaNav: React.FC<NavbarProps> = observer((props: NavbarProps) => {
  const {
    cartStore: { draftCart },
    generalStore: { navLinks, categories },
  } = useStore();
  const [isAuthModalOpen, setIsAuthModalOpen] = useAuthModal();
  const router = useRouter();
  if (
    (router.asPath.includes('goalbased') &&
      router.asPath.includes('edit-subscription')) ||
    router.asPath.includes('action=view&mode=edit-subscription') ||
    (router.asPath.includes('/products') &&
      router.asPath.includes('edit-subscription'))
  )
    return null;
  const editSubscription = draftCart.mode === Mode.EDIT_SUBSCRIPTION;
  return (
    <div id="target-container" className={' sticky top-0 z-50 '}>
      <ImpersonationBanner />
      <nav
        className={`relative z-50 mx-auto flex flex-wrap items-center justify-between border-b border-off-lite bg-white ${editSubscription && 'h-[61px]'}`}
      >
        <div className="container mx-0 flex items-center justify-between px-0 lg:mx-auto lg:px-8">
          <MenuBar
            value={false}
            onClick={() => props.setMenuBar(!props.menuBar)}
          />
          <Logo />
          <NavLinks
            navLinks={navLinks}
            categories={categories}
            setIsBlur={props.setIsBlur}
          />
          <UserActions setBlur={props.setIsBlur} />
        </div>
      </nav>
      <MegaNavMobile
        isOpen={props.menuBar}
        onClick={() => props.setMenuBar(!props.menuBar)}
        categories={categories}
        navLinks={navLinks}
      />
      <FeaturesReady timeout={2000}>
        <CartSlidingPane />
      </FeaturesReady>
      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={() => setIsAuthModalOpen(false)}
        navigatedFrom="navbar"
      />
    </div>
  );
});
export default MegaNav;

import Link from 'next/link';

import Button from '@/components/common/Button/Button';
import type { Mode } from '@/utils/constants';

export type TopButtonsType = {
  closeCart: () => void;
  mode?: Mode;
  openMealPlan: () => void;
  isMenuPage: boolean;
};
const TopButtons: React.FC<TopButtonsType> = ({
  closeCart,
  mode,
  openMealPlan,
  isMenuPage = false,
}: TopButtonsType) => {
  return (
    <div className="sticky top-0 z-50 flex items-center justify-center space-x-4 bg-white py-4 shadow-lg">
      {isMenuPage ? (
        <Button onClick={closeCart} theme="tofu">
          <span className="px-1 font-interMedium text-sm">Add more Items</span>
        </Button>
      ) : (
        <Button onClick={closeCart} theme="tofu">
          <Link
            href={{ pathname: '/menu/meals', query: { ...(mode && { mode }) } }}
            legacyBehavior
          >
            <span className="px-1 font-interMedium text-sm">
              Add more Items
            </span>
          </Link>
        </Button>
      )}
      <Button theme="secondary" onClick={openMealPlan}>
        <span className="px-1 font-interMedium text-sm">Add a Meal Plan</span>
      </Button>
    </div>
  );
};

export default TopButtons;

import { PopupButton } from '@typeform/embed-react';
import Image from 'next/legacy/image';
import React from 'react';

import Button from '@/components/common/Button/Button';
import TypeFormButton from '@/components/common/TypeFormButton/TypeFormButton';

export type EmptyCartType = {
  closeCart: () => void;
};

const EmptyCart: React.FC<EmptyCartType> = ({ closeCart }: EmptyCartType) => {
  const typeformId = process.env.NEXT_PUBLIC_MP_TYPEFORM || '';
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="pt-16">
        <Image
          src="/assets/images/svg/Cart_Disabled.svg"
          alt="Cart Basket"
          width={46.1}
          height={45.7}
        />
      </div>
      <div className="pt-6">
        <p className="font-interMedium text-sm text-grey-dark">
          You have no items in your cart
        </p>
      </div>

      <div className="pt-16">
        <Button
          type="button"
          link="/menu/meals"
          theme="secondary"
          onClick={closeCart}
        >
          <span className="p-1 font-interMedium text-base text-rhubarb-dark">
            Browse Full Menu
          </span>
        </Button>
      </div>

      <div className="relative flex w-full items-center px-12 py-8">
        <div className="grow border-t border-grey"></div>
        <span className="mx-4 shrink font-interMedium text-sm leading-none text-black">
          or
        </span>
        <div className="grow border-t border-grey"></div>
      </div>

      <div className="px-12">
        <p className="text-center font-interMedium text-[21px] leading-7 text-black">
          Discover the right meal<br></br>plan that suits your taste<br></br>and
          goals
        </p>
      </div>
      <div className="pb-4 pt-6">
        <PopupButton
          id={typeformId}
          data-tf-opacity="100"
          data-tf-inline-on-mobile
          data-tf-iframe-props="title=My Muscle Chef"
          data-tf-transitive-search-params
          data-tf-auto-focus
          data-tf-medium="snippet"
        >
          <TypeFormButton theme="primary">
            <span className="p-1 font-interMedium text-base">Get Started</span>
          </TypeFormButton>
        </PopupButton>
      </div>
    </div>
  );
};

export default EmptyCart;

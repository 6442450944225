import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback } from 'react';

import { PackAndDiscount } from '@/components/common/PackAndDiscount/PackAndDiscount';
import { PrimaryDisplayTag } from '@/components/common/PrimaryDisplayTag/PrimaryDisplayTag';
import QuantityButton from '@/components/common/QuantityButtonV2/QuantityButtonV2';
import type { ProductModelType } from '@/models/api';
import type { OrderPlan } from '@/models/cart/order-plan';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';
import { trackProductClicked } from '@/utils/track/tracker.helper';

export type ProductType = {
  product: ProductModelType;
  qty: number;
};

export type LineItemTypes = {
  plan: OrderPlan;
  product: ProductType;
};

const LineItem: React.FC<LineItemTypes> = observer(
  ({ plan, product }: LineItemTypes) => {
    const {
      netPrice,
      grossPrice,
      promotionTotal,
      cms,
      subProducts,
      hasSubProducts,
      slug,
    } = product.product;

    const name = cms?.primaryName ?? product.product.name;
    const image = cms ? cms.tileImage?.url : '';
    const primaryDisplayTag = cms?.primaryDisplayTag;

    const {
      cartStore: { cart, cartToUse },
    } = useStore();

    const { mode } = cartToUse;
    const { closeCart } = cart;
    const { qty } = product;
    const deleteProduct = () => {
      if (product && product.product) {
        plan?.deleteLineItem(product.product, 'cart');
      }
    };

    const handleAddProduct = useCallback(() => {
      if (product && product.product) {
        plan?.increaseLineItem(product.product, 1, 'cart');
      }
    }, [plan]);

    const handleDeleteProduct = useCallback(() => {
      if (product && product.product) {
        plan?.decreaseLineItem(product.product, 'cart');
      }
    }, [plan]);

    const packs =
      hasSubProducts && subProducts
        ? _.sumBy(subProducts, (sub) => sub.qty)
        : 0;

    const trackClick = () => {
      try {
        trackProductClicked(product, 'cart');
      } catch (error) {
        // ignore error
      }
    };

    return (
      <div className="mb-4 block last:pb-10">
        <div className="relative flex px-6 ">
          <div className="relative flex shrink-0" onClick={trackClick}>
            {image &&
              (mode !== Mode.EDIT_SUBSCRIPTION ? (
                <Link
                  href={{
                    pathname: `/products/${slug}`,
                    query: {
                      ...(mode && { mode }),
                    },
                  }}
                  className="max-h-[104px]"
                >
                  <Image
                    src={image}
                    alt="Cart Basket"
                    width={104}
                    height={104}
                    className="rounded-lg hover:cursor-pointer"
                    onClick={closeCart}
                  />
                </Link>
              ) : (
                <Image
                  src={image}
                  alt="Cart Basket"
                  width={104}
                  height={104}
                  className="rounded-lg"
                />
              ))}
          </div>
          <div className="flex w-full flex-col justify-between pl-3">
            {mode !== Mode.EDIT_SUBSCRIPTION ? (
              <Link
                href={{
                  pathname: `/products/${slug}`,
                  query: {
                    ...(mode && { mode }),
                  },
                }}
                legacyBehavior
              >
                <h5
                  onClick={closeCart}
                  className="line-clamp-2 max-w-[167px] font-interMedium text-sm leading-snug hover:cursor-pointer"
                >
                  {name}
                </h5>
              </Link>
            ) : (
              <h5 className="line-clamp-2 max-w-[167px] font-interMedium text-xs leading-snug">
                {name}
              </h5>
            )}

            {!hasSubProducts && primaryDisplayTag && (
              <PrimaryDisplayTag tag={primaryDisplayTag} page={'cart'} />
            )}

            {/* discount */}
            {hasSubProducts && promotionTotal && (
              <PackAndDiscount
                promotionTotal={promotionTotal}
                grossPrice={grossPrice}
                packs={packs}
                quantityTag={cms?.quantityTag}
              />
            )}

            {/* QTY */}
            <div className="flex w-full items-end justify-between">
              <QuantityButton
                qty={qty}
                handleDeleteProduct={handleDeleteProduct}
                handleAddProduct={handleAddProduct}
                size={'small'}
                page="cart"
              />

              <p className="flex pb-1.5 font-interSemibold leading-4">
                {(product.product.isDiscountable &&
                  product.product.getDiscountedPrice(plan?.discount) !==
                    netPrice) ||
                promotionTotal ? (
                  <del className="pr-1 font-interMedium text-xs text-grey-dark">
                    ${grossPrice?.toFixed(2)}
                  </del>
                ) : null}
                <span>
                  $
                  {product.product
                    .getDiscountedPrice(plan?.discount)
                    .toFixed(2)}
                </span>
              </p>
            </div>

            <p className="absolute right-2 top-2 cursor-pointer">
              <Image
                src="/assets/images/svg/delete.svg"
                alt="Delete Cart"
                width={20}
                height={15}
                onClick={deleteProduct}
                className="object-none"
              />
            </p>
          </div>
        </div>
        <hr className="my-4" />
      </div>
    );
  },
);

export default LineItem;

import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

const SubscriptionBar = observer(() => {
  const {
    cartStore: { draftCart },
    productStore: {
      search: { resetSearch },
    },
  } = useStore();
  const router = useRouter();
  const isAccountPage = router.pathname === '/account';
  const isSwapPage = router.pathname.includes('/swap');
  const editSubscription = draftCart.mode === Mode.EDIT_SUBSCRIPTION;
  const hideNav =
    draftCart && editSubscription && !isAccountPage && !isSwapPage;
  const handleBack = () => {
    router.push({
      pathname: '/account',
      query: {
        cartOpen: true,
        ...(draftCart.mode && { mode: draftCart.mode }),
      },
    });
    resetSearch();
  };

  const pdp = router.pathname.includes('/products');
  const goalBased = router.pathname.includes('/goalbased');
  const justify = pdp ? 'justify-center' : 'justify-start';
  if (!hideNav) return null;
  const subscriptionBarStyles = (
    pdpPage: boolean,
    goalBasedPage: boolean,
    editSub: boolean,
  ) => {
    const baseStyles = 'sticky z-50 h-12  md:h-[62px] xl:h-[65px]';
    if (editSub) {
      if (pdpPage || goalBasedPage) {
        return `${baseStyles} top-0`;
      }
      return `${baseStyles} top-[50px] md:top-[56px]`;
    }
    return 'h-[60px]';
  };
  return (
    <div
      className={`w-full bg-black ${subscriptionBarStyles(pdp, goalBased, editSubscription)}`}
    >
      <div
        className={`container sticky top-0 z-50  flex-row p-4 px-6 font-interMedium text-white xl:px-10 ${justify} flex`}
      >
        <div className={pdp ? 'hidden' : 'flex w-1/5 items-center md:w-2/5'}>
          <div className="pr-3">
            <button
              onClick={() => handleBack()}
              id="close"
              className="flex items-center"
            >
              <Image
                id="back"
                src="https://www.datocms-assets.com/81001/1706494214-arrow_back.svg"
                className="cursor-pointer"
                alt="back"
                width={15}
                height={15}
              />
            </button>
          </div>

          <Link
            href={{
              pathname: '/account',
              query: {
                mode: Mode.EDIT_SUBSCRIPTION,
                cartOpen: true,
              },
            }}
            legacyBehavior
          >
            <div className="hidden w-auto cursor-pointer pl-4 text-lg md:flex">
              Back to Subscription
            </div>
          </Link>
        </div>

        <div className="my-auto whitespace-nowrap text-center text-sm	">
          Adding items to your subscription
        </div>
      </div>
    </div>
  );
});
export default SubscriptionBar;

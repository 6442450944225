import _ from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import type { NavbarLinkRecordModelType } from '@/models/api';

interface LinkProp {
  navLink: NavbarLinkRecordModelType;
  trackOnClick?: () => void;
  children: React.ReactNode;
}

function NavLink({ navLink, trackOnClick, children }: LinkProp) {
  const router = useRouter();
  const [scale, setScale] = useState('scale-0');

  const isTouchDevice = window.matchMedia('(any-pointer: coarse)').matches;

  useEffect(() => {
    setScale('scale-0');
  }, [router.asPath]);

  const onClick = (e: any) => {
    if (!isTouchDevice) {
      setScale('scale-0');
      return;
    }

    const hasNoChildrenLinks =
      _.isEmpty(navLink.extraLinks) && _.isEmpty(navLink.subLinks);
    if (hasNoChildrenLinks && navLink.relativePathLink) {
      router.push(navLink.relativePathLink);
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    setScale('');
  };

  return (
    <li
      style={{ height: '70px' }}
      className="nav-links static hover:border-b-2 hover:border-red-600"
      onMouseOver={() => setScale('')}
      onMouseOut={() => setScale('scale-0')}
    >
      <Link
        href={navLink.relativePathLink || navLink.externalUrl || '/'}
        style={{ height: '70px' }}
        className={`flex items-center justify-center font-interMedium capitalize`}
        title={navLink.text || ''}
        onClick={(e) => {
          if (trackOnClick) {
            trackOnClick();
          }
          onClick(e);
        }}
      >
        {navLink.text}
      </Link>
      <div className={`${scale} ease-in-out`}>{children}</div>
    </li>
  );
}

export default NavLink;

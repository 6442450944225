import Image from 'next/image';
import React from 'react';

import type { MenuHierarchyRecordModelType } from '@/models/api';

interface MobileCategoryProps {
  category: MenuHierarchyRecordModelType;
  parentCategory?: MenuHierarchyRecordModelType | undefined;
  goToCategory: (
    category: MenuHierarchyRecordModelType,
    parentCategory: MenuHierarchyRecordModelType | undefined
  ) => void;
}

const MobileCategory = ({
  category,
  parentCategory,
  goToCategory,
}: MobileCategoryProps) => {
  return (
    <li
      className="flex h-[44px] w-full items-center justify-between py-2"
      onClick={() => goToCategory(category, parentCategory)}
    >
      <span className="flex leading-6">
        {category.name}
        {category.extraLabel && (
          <span className="ml-4 flex rounded-[40px] bg-lemon px-2 py-0 font-interSemibold text-xs md:py-1">
            {category.extraLabel}
          </span>
        )}
      </span>

      {category.childrenCategories &&
        category.childrenCategories.length > 0 && (
          <Image
            width={30}
            height={30}
            src="https://www.datocms-assets.com/81001/1701308267-arrow-down.svg"
            alt=""
          />
        )}
    </li>
  );
};

export default MobileCategory;

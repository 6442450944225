import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import type { NavbarLinkRecordModelType } from '@/models/api';

interface MobileNavLinkProps {
  navLink: NavbarLinkRecordModelType;
  setSelectedLink?: (link: NavbarLinkRecordModelType) => void;
  selectedLink?: NavbarLinkRecordModelType;
  onClick: () => void;
}

const MobileNavLink = ({
  navLink,
  setSelectedLink,
  onClick,
}: MobileNavLinkProps) => {
  const showSubLinks = () => {
    if (navLink.subLinks && navLink.subLinks.length > 0 && setSelectedLink) {
      setSelectedLink(navLink);
    } else {
      onClick();
    }
  };

  if (navLink.subLinks && navLink.subLinks.length > 0) {
    return (
      <li
        className="flex h-[44px] w-full items-center justify-between leading-7 text-black"
        onClick={showSubLinks}
      >
        {navLink.text}

        <Image
          width={30}
          height={30}
          src="https://www.datocms-assets.com/81001/1701308267-arrow-down.svg"
          alt=""
        />
      </li>
    );
  }

  return (
    <Link href={navLink.relativePathLink || navLink.externalUrl || '/'}>
      <li
        className="flex h-[44px] w-full items-center justify-between leading-7 text-black"
        onClick={showSubLinks}
      >
        {navLink.text}
      </li>
    </Link>
  );
};

export default MobileNavLink;
